<template>
  <v-container fluid class="mt-7">
    <v-row>
      <v-col cols="12">
        <v-card color="transparent" elevation="0" tile dark>
          <v-card-title class="align-center justify-center text-h4">
            <b>Upload Image</b>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" sm="8" md="6">
        <v-card outlined class="pa-4">
          <v-card-text class="text-center">
            <div class="text-h6 mb-2">Order Number:</div>
            <div class="text-h5 mb-4">{{ orderNumber }}</div>
            
            <v-file-input
              v-model="selectedFile"
              accept="image/*"
              :rules="fileRules"
              prepend-icon="mdi-image"
              label="Select or Take Photo"
              show-size
              @change="onFileSelected"
            ></v-file-input>

            <v-img
              v-if="imagePreview"
              :src="imagePreview"
              max-height="300"
              contain
              class="grey darken-4 mb-4"
            ></v-img>

            <v-textarea
              v-model="comments"
              label="Comments"
              rows="3"
              outlined
              class="mb-4"
            ></v-textarea>

            <v-btn
              block
              color="primary"
              :loading="uploading"
              :disabled="!selectedFile"
              @click="uploadImage"
              class="mt-4"
            >
              Upload Photo
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Success/Error Snackbar -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'mobile-upload',
  data() {
    return {
      orderNumber: '',
      selectedFile: null,
      imagePreview: null,
      uploading: false,
      snackbar: {
        show: false,
        text: '',
        color: 'success'
      },
      fileRules: [
        v => !v || v.size < 5000000 || 'Photo size should be less than 5 MB!',
      ],
      comments: ''
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getToken'])
  },
  methods: {
    onFileSelected(file) {
      if (file) {
        this.imagePreview = URL.createObjectURL(file)
      } else {
        this.imagePreview = null
      }
    },
    async uploadImage() {
      if (!this.selectedFile) return

      this.uploading = true
      const formData = new FormData()
      formData.append('file', this.selectedFile)
      formData.append('order_no', this.orderNumber)
      formData.append('comments', this.comments)

      try {
        await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        
        this.snackbar = {
          show: true,
          text: 'Photo uploaded successfully!',
          color: 'success'
        }
        
        // Reset form
        this.selectedFile = null
        this.imagePreview = null
        this.comments = ''
        
      } catch (error) {
        this.snackbar = {
          show: true,
          text: 'Failed to upload photo. Please try again.',
          color: 'error'
        }
        console.error('Upload error:', error)
      } finally {
        this.uploading = false
      }
    }
  },
  mounted() {
    // Get order number from route query parameter
    this.orderNumber = this.$route.query.order || ''
    if (!this.orderNumber) {
      this.snackbar = {
        show: true,
        text: 'No order number provided',
        color: 'error'
      }
    }
  }
}
</script>

<style scoped>
.v-file-input ::v-deep .v-file-input__text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
